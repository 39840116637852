<script setup lang="ts">
const props = defineProps<{
  id?: string
  title: string
}>()
</script>

<template>
  <section :id="id" class="px-4 py-8 lg:py-16">
    <div class="container mx-auto max-w-5xl">
      <div class="text-center">
        <h2 class="mb-4 text-2xl font-medium">{{ props.title }}</h2>
      </div>
      <slot />
    </div>
  </section>
</template>
